import clsx from 'clsx';
import Link from 'next/link';
import { FC, Suspense, lazy, useMemo } from 'react';
import { usePdvStore } from 'stores/pdv';
import * as pdvSelectors from 'stores/pdv/selectors';
import { useUiDispatch } from 'stores/ui';
import { getCategoryUrl } from 'utils/catalog/get-category-url';
import { getProductPricing } from 'utils/product';
import InstallmentSkeleton from '../Installment/InstallmentSkeleton';
import PriceSkeleton from '../Price/PriceSkeleton';
import PromotionPriceSkeleton from '../Promotion/PromotionPriceSkeleton';
import PromotionSkeleton from '../Promotion/PromotionSkeleton';
import RatingScoreContainer from '../RatingScore/RatingScoreContainer';
import { PageInteractionType } from '../types';

const MainInfoAsyncSection = lazy(() => import('./MainInfoAsyncSection'));
const MainInfoIcons = lazy(() => import('./MainInfoIcons'));

export const REVIEW_SECTION_POSITION_NUMBER = 2;

const MainInfo: FC = () => {
  const product = usePdvStore(pdvSelectors.product);
  const uiDispatch = useUiDispatch();
  const selectedSimple = usePdvStore(pdvSelectors.selectedSimple);
  const productPricing = getProductPricing(product, selectedSimple);
  const brandUrl = useMemo(
    () =>
      getCategoryUrl({
        brandId: product.BrandId || '',
        brandKey: product.BrandUrlKey || '',
      }),
    [product],
  );

  const onClickBrand = () => {
    uiDispatch.addPdvInteractedListener({
      interactionType: PageInteractionType.BRANDNAME_CLICKED,
      interactionValue: 'key_info',
    });
  };

  return (
    <div className="pb-4">
      <div className="flex items-start justify-between">
        <h1>
          <Link href={brandUrl}>
            <a
              onClick={onClickBrand}
              className={clsx(
                'flex items-center text-base font-bold hover:underline',
                'tablet:text-2xl tablet:font-medium tablet:leading-9',
              )}
            >
              <span
                data-test-id="productBrand"
                className="mr-2"
              >
                {product.Brand}
                <span className="ml-2 inline-flex items-center gap-2">
                  <Suspense fallback={null}>
                    <MainInfoIcons product={product} />
                  </Suspense>
                </span>
              </span>
            </a>
          </Link>

          <div className="hidden">
            <span>{product.Name}</span>
            <span>{productPricing.Price}</span>
            <span>{productPricing.SpecialPrice}</span>
          </div>
        </h1>

        <RatingScoreContainer />
      </div>
      <div>
        <span
          data-test-id="productName"
          className={'mr-2 align-middle text-base text-grey-80'}
        >
          {product.Name}
        </span>
      </div>

      <Suspense
        fallback={
          <>
            <div className="pb-4">
              <PromotionPriceSkeleton />
              <PriceSkeleton />
              <InstallmentSkeleton />
            </div>

            <PromotionSkeleton />
          </>
        }
      >
        <MainInfoAsyncSection />
      </Suspense>
    </div>
  );
};

export default MainInfo;
