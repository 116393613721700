import { FC, memo } from 'react';
import Breadcrumbs from 'components/Breadcrumbs';
import { usePdvStore } from 'stores/pdv';
import * as pdvSelectors from 'stores/pdv/selectors';
import { getBreadcrumbs } from 'utils/product';

interface Props {
  className?: string;
}

const PDVBreadcrumbsSSR: FC<Props> = ({ className }) => {
  const product = usePdvStore(pdvSelectors.product);
  const breadcrumbs = getBreadcrumbs(product);

  return (
    <Breadcrumbs
      key={product.ConfigSku}
      className={className}
      items={breadcrumbs}
    />
  );
};

export default memo(PDVBreadcrumbsSSR);
