export const enum TestIdsPDV {
  ACTION_BUTTONS_CART_LINK = 'cartLink',
  ADD_TO_CART_BUTTON = 'addToCartButton',
  FIT_INDICATOR_SUGGESTION_TEXT = 'fitIndicatorSuggestionText',
  GO_TO_BAG_BUTTON = 'goToBag',
  MAIN_WISHLIST_BUTTON = 'mainWishlistBtn',
  MAIN_WISHLIST_BUTTON_MOBILE = 'mobileMainWishlistBtn',
  MEDIA_ITEM = 'mediaItem',
  PRODUCT_IMAGE = 'productImages',
  PRODUCT_LINK = 'productLink',
  PRODUCT_CARE_ATTRIBUTES = 'productCareAttributes',
  REVIEW_TRANSLATION_BUTTON = 'reviewTranslationButton',
  SIZE_BUTTON_SECTION = 'sizeButtonSection',
  SIZE_GUIDE_ONBOARDING_NAVIGATION = 'sizeGuideOnboardingNavigation',
  SIZE_MINI_TABS = 'sizeMiniTabs',
  SIZE_SELECTION_OPTION_BOX = 'sizeSelectionOptionBox',
  STORE_SECTION = 'storeSection',
  BEST_PRICE_GUARANTEED_LINK = 'bestPriceGuaranteedLink',
  MEDIA_BANNER = 'mediaBanner',
  TRY_SEARCH_BUTTON = 'trySearchButton',
  VIEW_ALL_BUTTON = 'viewAllButton',
}
