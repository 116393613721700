import isServer from 'utils/is-server';

const shouldTrackLineAds = (
  targetWindow: Window,
): targetWindow is Window & {
  _lt: NonNullable<globalThis.Window['_lt']>;
  __ENV__: {
    CC: string;
    LINE_ADS_ID: string;
  };
} => {
  if (isServer()) {
    return false;
  }

  const lineAdsTrackingFn = targetWindow._lt;
  const lineAdsId = targetWindow.__ENV__.LINE_ADS_ID;

  return (
    typeof lineAdsTrackingFn === 'function' &&
    typeof lineAdsId === 'string' &&
    lineAdsId.length > 0 &&
    lineAdsId !== 'undefined'
  );
};

export const sendViewItemDetailEvent = () => {
  if (shouldTrackLineAds(window)) {
    window._lt('send', 'cv', { type: 'ViewItemDetail' }, [window.__ENV__.LINE_ADS_ID]);
  }
};

export const sendAddToCartEvent = () => {
  if (shouldTrackLineAds(window)) {
    window._lt('send', 'cv', { type: 'AddToCart' }, [window.__ENV__.LINE_ADS_ID]);
  }
};

export const sendInitiateCheckOutEvent = () => {
  if (shouldTrackLineAds(window)) {
    window._lt('send', 'cv', { type: 'InitiateCheckOut' }, [window.__ENV__.LINE_ADS_ID]);
  }
};

export const sendCompleteRegistrationEvent = () => {
  if (shouldTrackLineAds(window)) {
    window._lt('send', 'cv', { type: 'CompleteRegistration' }, [window.__ENV__.LINE_ADS_ID]);
  }
};
