import React, { useMemo } from 'react';
import { StructuredData } from 'components/SEO/StructuredData';
import { usePdvStore } from 'stores/pdv';
import * as pdvSelectors from 'stores/pdv/selectors';
import { getProductDetailJsonLd } from './utils';

export const PDVStructuredData = () => {
  const product = usePdvStore(pdvSelectors.product);
  const reviewStatistics = usePdvStore(pdvSelectors.reviewStatistics);

  const productJsonLd = useMemo(
    () => getProductDetailJsonLd({ product, reviewStatistics }),
    [product, reviewStatistics],
  );

  return (
    <StructuredData
      id="productStructuredData"
      jsonLd={productJsonLd}
    />
  );
};
