import { ZDTProduct } from '@zalora/doraemon-ts';
import clsx from 'clsx';
import { FC, Suspense, lazy, memo, useMemo } from 'react';
import { usePdvStore } from 'stores/pdv';
import * as pdvSelectors from 'stores/pdv/selectors';
import VariationContainer from './VariationContainer';

const VariationDetailHeader = lazy(() => import('./VariationDetailHeader'));

interface Props {
  className?: string;
}

const getProductVariations = (product: ZDTProduct.Product): ZDTProduct.ProductVariation[] => {
  const { Variations, ConfigSku, Color, MainImageUrl } = product;

  if (Variations && Variations.length > 0) {
    return Variations;
  }

  return [
    {
      Color,
      ConfigSku: ConfigSku || '',
      HexColor: '',
      Image: MainImageUrl || '',
      Link: '',
      Slug: '',
    },
  ];
};

const VariationDetail: FC<Props> = ({ className }) => {
  const product = usePdvStore(pdvSelectors.product);
  const formattedVariations = useMemo(() => getProductVariations(product), [product]);

  return (
    <div className={clsx('mt-2 tablet:mt-5', className)}>
      <Suspense fallback={<div className="h-5" />}>
        <VariationDetailHeader formattedVariations={formattedVariations} />
      </Suspense>

      <VariationContainer
        product={product}
        variations={formattedVariations}
      />
    </div>
  );
};

export default memo(VariationDetail);
