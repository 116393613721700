import clsx from 'clsx';
import { Suspense, lazy, startTransition, useCallback, useRef, useState } from 'react';
import { TestIdsPDV } from 'constants/e2eIds/pdv-test-ids';
import useHydrated from 'hooks/useHydrated';
import MediaItem from './MediaItem';

const MediaFloatingSection = lazy(() => import('./MediaFloatingSection'));

interface ChildrenRenderProps<T> {
  item: T;
  index: number;
}

export interface Props<T> {
  items: T[];
  getKeyField?: (item: T) => string;
  children: (props: ChildrenRenderProps<T>) => JSX.Element;
  isHavingBanner?: boolean | null;
  onClickBanner?: () => void;
}

/**
 * Displaying the media list of a product including pictures and videos
 */
const MediaList = <T,>({
  items = [],
  isHavingBanner,
  onClickBanner,
  getKeyField,
  children,
}: Props<T>) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const isHydrated = useHydrated();

  const onVisible = useCallback((index: number) => {
    startTransition(() => {
      setActiveIndex(index);
    });
  }, []);

  if (items.length <= 0) {
    return null;
  }

  return (
    <div className={clsx('relative', { 'mb-4 tablet:mb-0': isHavingBanner })}>
      <div
        ref={containerRef}
        className="no-scrollbar flex snap-x snap-mandatory overflow-x-auto tablet:grid tablet:grid-cols-2 tablet:gap-2"
      >
        {items.map((item, index) => (
          <MediaItem
            data-test-id={TestIdsPDV.MEDIA_ITEM}
            key={getKeyField ? getKeyField(item) : index}
            container={containerRef}
            index={index}
            style={{
              aspectRatio: index < 1 ? ' 1/1.45' : '1/1.44',
            }}
            className="relative shrink-0 basis-full snap-center snap-always"
            onVisible={onVisible}
          >
            {children({ item, index })}
          </MediaItem>
        ))}
      </div>

      {isHydrated ? (
        <Suspense fallback={null}>
          <MediaFloatingSection
            items={items}
            activeIndex={activeIndex}
            onClickBanner={onClickBanner}
          />
        </Suspense>
      ) : null}
    </div>
  );
};

export default MediaList;
