import { ZDTProduct, ZDTRatingsReviews } from '@zalora/doraemon-ts';
import { getCurrencyCode } from 'utils/money';
import { stripTags } from 'utils/open-graph';

// https://developers.google.com/search/docs/appearance/structured-data/review-snippet#aggregate_ratings
// Tool for checking: https://search.google.com/test/rich-results
export const getProductDetailJsonLd = ({
  product,
  reviewStatistics,
}: {
  product: ZDTProduct.Product;
  reviewStatistics: ZDTRatingsReviews.ProductReviewStatistics;
}) => {
  interface ProductJsonLd {
    [key: string]: unknown;
    '@context': 'https://schema.org/';
    '@type': 'Product';
    name: string;
    image: string;
    description: string;
    sku: string;
    brand: {
      '@type': 'Brand';
      name: string;
    };
    offers: {
      '@type': 'Offer';
      url: string;
      priceCurrency: string;
      price: string;
      itemCondition: string;
      availability: string;
    };
    aggregateRating?: {
      '@type': 'AggregateRating';
      ratingValue: number;
      reviewCount: number;
    };
  }

  const data: ProductJsonLd = {
    '@context': 'https://schema.org/',
    '@type': 'Product',
    name: `${product.Name}`,
    image: `${product.MainImageUrl}`,
    description: product.ShortDescription ? `${stripTags(product.ShortDescription)}` : '',
    sku: `${product.ConfigSku}`,
    brand: { '@type': 'Brand', name: `${product.Brand}` },
    offers: {
      '@type': 'Offer',
      url: `${product.Url}`,
      priceCurrency: getCurrencyCode(),
      price: `${product.SpecialPrice || product.Price}`,
      itemCondition: 'https://schema.org/NewCondition',
      availability: 'https://schema.org/InStock',
    },
  };

  if (!!reviewStatistics.ReviewCount) {
    Object.assign(data, {
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: reviewStatistics.AvgRating,
        reviewCount: reviewStatistics.ReviewCount,
      },
    });
  }

  return data;
};
