import { useEffect, useState } from 'react';

const useScrollToObserver = ({ scrollTo = 100 }) => {
  const [isIntersecting, setIsIntersecting] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > scrollTo) {
        setIsIntersecting(true);

        // Remove event listener after it's triggered
        removeEventListener();
      }
    };

    const removeEventListener = () => {
      window.removeEventListener('scroll', handleScroll);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrollTo]);

  return isIntersecting;
};

export default useScrollToObserver;
