import { FC } from 'react';
import { SkeletonItem } from 'components/skeleton';

const PriceSkeleton: FC = () => {
  return (
    <div className="flex w-3/5 gap-x-2 pt-1">
      <SkeletonItem height="h-[26px] w-3/6" />
      <SkeletonItem height="h-[26px] w-2/6" />
      <SkeletonItem height="h-[26px] w-1/6" />
    </div>
  );
};

export default PriceSkeleton;
