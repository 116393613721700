import * as productUtils from 'utils/product';
import { PdvStore, PdvStoreActions, PdvStoreObservables } from './types';

export const onSizeSelected = (state: PdvStoreObservables) => {
  return state.onSizeSelected;
};

export const product = (state: PdvStoreObservables) => {
  return state.product;
};

export const reviewStatistics = (state: PdvStoreObservables) => {
  return state.reviewStatistics;
};

export const specs = (state: PdvStoreObservables) => {
  return state.specs;
};

export const care = (state: PdvStoreObservables) => {
  return state.care;
};

export const selectedSizeData = (state: PdvStoreObservables) => {
  return state.selectedSizeData;
};

export const sizeErrorMessage = (state: PdvStoreObservables) => {
  return state.sizeErrorMessage;
};

export const shouldShowSizeGuideOnboarding = (state: PdvStoreObservables) => {
  return state.shouldShowSizeGuideOnboarding;
};

export const outOfStockContext = (state: PdvStoreObservables) => {
  return state.outOfStockContext;
};

export const selectedSimple = (state: PdvStoreObservables) => {
  return productUtils.getSimpleBySize(state.product, state.selectedSizeData.size);
};

export const actions = (state: PdvStore): Pick<PdvStore, keyof PdvStoreActions> => {
  return {
    setSelectedSizeData: state.setSelectedSizeData,
    setSizeErrorMessage: state.setSizeErrorMessage,
    setProduct: state.setProduct,
    showSizeGuideOnboarding: state.showSizeGuideOnboarding,
    setOutOfStockContext: state.setOutOfStockContext,
  };
};
