import dynamic from 'next/dynamic';
import Head from 'next/head';
import { FC, Suspense, lazy, useEffect } from 'react';
import { CitrusTracking } from 'components/ZAP';
import PDVBreadcrumbsSSR from 'components/pdv/PDVBreadcrumbs/PDVBreadcrumbsSSR';
import PDVOpenGraphTags from 'components/pdv/PDVOpenGraphTags';
import PDVPageInfo from 'components/pdv/PDVPageInfo';
import { PDVStructuredData } from 'components/pdv/PDVStructuredData';
import PDVUniversalLinks from 'components/pdv/PDVUniversalLinks';
import ProductDetail from 'components/pdv/ProductDetail/ProductDetail';
import { TrackingDataProps } from 'constants/tracking';
import withDynamicLoadScrollTo from 'hocs/withDynamicLoadScrollTo';
import { useInvokeCallbackOnceWhen } from 'hooks/useInvokeCallbackOnceWhen';
import * as lineAdsTracking from 'services/line-ads-tracking';
import { useUiStore } from 'stores/ui';
import * as uiSelectors from 'stores/ui/selectors';
import { getStaticAsset } from 'utils/domains';
import { initialiseTrackingData } from 'utils/tracking';

const PDVBreadcrumbs = lazy(() => import('components/pdv/PDVBreadcrumbs'));
const PDVAsyncSection = lazy(() => import('./PDVAsyncSection'));
const PDVRecommendationSection = withDynamicLoadScrollTo(
  dynamic(() => import('./PDVRecommendationSection')),
  { scrollTo: 50 },
);

const ModalDialogRenderer = lazy(() => import('components/ModalDialog/ModalDialogRenderer'));

const PdvPage: FC = () => {
  const activeSegment = useUiStore(uiSelectors.activeSegment);

  useEffect(() => {
    const trackingData: TrackingDataProps = {
      activeSegment,
    };

    initialiseTrackingData(trackingData);
  }, [activeSegment]);

  useInvokeCallbackOnceWhen(() => {
    lineAdsTracking.sendViewItemDetailEvent();
  });

  return (
    <>
      <Head>
        <link
          rel="preload"
          as="image"
          // this is a hint to preload the skeleton product image for catalog page
          // It helps to fetch the image ASAP, then our LCP will be better
          // the size of this image is 1kb so it doesnt affect other pages too much
          href={getStaticAsset('/static-assets/images/product_skeleton.webp')}
        />
      </Head>

      <Suspense fallback={<div className="h-10 desktop:hidden" />}>
        <PDVAsyncSection />
      </Suspense>

      <Suspense fallback={null}>
        <PDVPageInfo />
        <PDVOpenGraphTags />
        <PDVStructuredData />
        <PDVUniversalLinks />
        <CitrusTracking />
      </Suspense>

      <div className="mx-auto max-w-screen-xl">
        <Suspense fallback={<PDVBreadcrumbsSSR className="hidden py-5 tablet:flex" />}>
          <PDVBreadcrumbs className="hidden py-5 tablet:flex" />
        </Suspense>

        <ProductDetail />

        <PDVRecommendationSection />
      </div>

      <Suspense fallback={null}>
        <ModalDialogRenderer />
      </Suspense>
    </>
  );
};

export default PdvPage;
