import { FC, PropsWithChildren, Suspense, lazy } from 'react';
import Header from 'components/Layout/ResponsiveHeader';
import DesktopFooter from './DesktopLayout/DesktopFooter';
import { UspBarSkeleton } from './DesktopLayout/UspBar/UspBarSkeleton';
import CountriesList from './Footer/CountriesList/CountriesList';
import DarkFooter from './Footer/DarkFooter/DarkFooter';
import AppSmartBannerSkeleton from './MobileLayout/AppSmartBanner/AppSmartBannerSkeleton';
import MobileFooter from './MobileLayout/MobileFooter';

const LayoutAsyncSection = lazy(() => import('./LayoutAsyncSection'));

const PDVLayout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <>
      <Suspense
        fallback={
          <>
            <AppSmartBannerSkeleton />
            <UspBarSkeleton />
          </>
        }
      >
        <LayoutAsyncSection shouldShownBackToTop />
      </Suspense>

      <div className="flex min-h-0 flex-col">
        <Header
          className="hidden desktop:block"
          shouldShowMobileSearch={false}
          shouldShowMobileLogo={false}
        />
        <main className="min-h-screen w-full shrink grow pb-[70px] desktop:pb-0 ">{children}</main>
        <Suspense fallback={null}>
          <DesktopFooter className="hidden desktop:flex" />
          <MobileFooter className="mb-16 tablet:mb-0 tablet:block" />
        </Suspense>

        {/* Always render for SEO purposes but hidden */}
        <Suspense fallback={null}>
          <CountriesList />
          <DarkFooter />
        </Suspense>
      </div>
    </>
  );
};

export default PDVLayout;
