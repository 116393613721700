import { ZDTProduct } from '@zalora/doraemon-ts';
import { isValidHexColor } from 'utils/validation';

export enum VariationsType {
  REGULAR = 1,
  BEAUTY_ALL_HEX = 2,
  BEAUTY_IMAGE_HEX = 3,
}

export const getVariantLayout = (
  variations: ZDTProduct.ProductVariation[],
  isBeautySegment: boolean,
  isViewAll: boolean,
) => {
  /**
   * Default layout of NORMAL segment
   */
  let variationsType: VariationsType = VariationsType.REGULAR;
  let defaultLayout =
    'mx-auto mt-4 flex no-scrollbar auto-cols-min flex-nowrap gap-x-1 gap-y-2 overflow-x-auto tablet:overflow-hidden desktop:overflow-hidden pb-4';
  let gridLayout = 'tablet:flex tablet:flex-wrap';
  let gridItem = 'w-15 min-w-15 tablet:w-12 tablet:min-w-12';

  if (isViewAll) {
    defaultLayout = 'mx-auto mt-4 gap-x-2 gap-y-6 pb-4';
    gridLayout = 'grid grid-cols-[repeat(auto-fill,_minmax(0,_80px))] place-content-center';
    gridItem = 'w-20 min-w-20';
  }

  /**
   * Layout of BEAUTY segment
   */
  if (isBeautySegment) {
    if (variations.every((v) => !isValidHexColor(v.HexColor || ''))) {
      variationsType = VariationsType.REGULAR;
    } else if (variations.every((v) => !v.Image)) {
      return { defaultLayout, ...getBeautyAllHexLayout(isViewAll) };
    } else {
      return { defaultLayout, ...getBeautyImageHexLayout(isViewAll) };
    }
  }

  return { variationsType, defaultLayout, gridLayout, gridItem };
};

const getBeautyImageHexLayout = (isViewAll: boolean) => {
  const variationsType = VariationsType.BEAUTY_IMAGE_HEX;
  let gridLayout = 'gap-y-2 gap-x-2 tablet:flex tablet:flex-wrap';
  let gridItem = 'w-14 min-w-14';

  if (isViewAll) {
    gridLayout = 'flex flex-col items-center w-full gap-y-0';
    gridItem = 'w-full py-1';
  }

  return { variationsType, gridLayout, gridItem };
};

const getBeautyAllHexLayout = (isViewAll: boolean) => {
  const variationsType = VariationsType.BEAUTY_ALL_HEX;
  let gridLayout = 'tablet:flex tablet:flex-wrap';
  let gridItem = 'w-15 min-w-15';

  if (isViewAll) {
    gridLayout = 'flex flex-col items-center w-full';
    gridItem = 'w-full py-2';
  }

  return { variationsType, gridLayout, gridItem };
};
