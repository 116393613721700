import { ZDTProduct, ZDTCatalog } from '@zalora/doraemon-ts';
import clsx from 'clsx';
import { FC, Suspense, lazy, useMemo } from 'react';
import Loading from 'components/Loading';
import withDynamicLoadIntersection from 'hocs/withDynamicLoadIntersection';
import { SegmentValue } from 'types/Segment';
import { getSourceSegment } from 'utils/product';
import VariationListPlaceholder from './VariationListPlaceholder';
import { getVariantLayout } from './variant-layout';

const VariationItemViewAll = lazy(() => import('./VariationItemViewAll'));

interface Props {
  isViewAll?: boolean;
  selectedSize?: ZDTProduct.Size;
  variations: ZDTProduct.ProductVariation[];
  product: ZDTProduct.Product | ZDTCatalog.Product;
}

const VariationContainer: FC<Props> = ({ isViewAll, selectedSize, variations = [], product }) => {
  const isBeautySegment = product
    ? getSourceSegment(product).toLowerCase() === SegmentValue.BEAUTY
    : false;

  const { variationsType, defaultLayout, gridLayout, gridItem } = useMemo(
    () => getVariantLayout(variations, isBeautySegment, isViewAll ?? false),
    [variations, isBeautySegment, isViewAll],
  );

  /**
   * Because Variation content is dynamic data, so we need to place it here to use get product list
   * - render Placeholder based on ProductVariation is help to avoid CLS
   * - render VariationItem Links at SSR to support SEO
   */
  const VariationList = useMemo(
    () =>
      withDynamicLoadIntersection(() => import('./VariationList'), {
        fallback: (
          <VariationListPlaceholder
            variations={variations}
            product={product}
            variationsType={variationsType}
            className={gridItem}
            defaultLayout={defaultLayout}
            gridLayout={gridLayout}
          />
        ),
      }),
    [product.ConfigSku],
  );

  if (isViewAll) {
    return (
      <div className={clsx(defaultLayout, gridLayout)}>
        <Suspense
          fallback={
            <div className="min-h-28">
              <Loading />
            </div>
          }
        >
          {variations.map((variation) => {
            const isActivated = variation.ConfigSku === product.ConfigSku;

            return (
              <VariationItemViewAll
                product={product}
                key={variation.ConfigSku}
                className={gridItem}
                isActivated={isActivated}
                variation={variation}
                variationsType={variationsType}
                selectedSize={selectedSize}
              />
            );
          })}
        </Suspense>
      </div>
    );
  }

  return (
    <VariationList
      variationsType={variationsType}
      gridItem={gridItem}
      selectedSize={selectedSize}
      variations={variations}
      product={product}
      defaultLayout={defaultLayout}
      gridLayout={gridLayout}
    />
  );
};

export default VariationContainer;
