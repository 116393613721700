import Head from 'next/head';
import { FC, MouseEventHandler, Suspense, lazy, useState } from 'react';
import Loading from 'components/Loading';
import { useDeviceType } from 'hooks/useDeviceType';
import { usePdvStore } from 'stores/pdv';
import * as pdvSelectors from 'stores/pdv/selectors';
import { useUiDispatch } from 'stores/ui';
import { getMainImageURL } from 'utils/product';
import { MediaCard } from '../MediaCard';
import { MediaList } from '../MediaList';
import { PageInteractionType } from '../types';

const MediaDialog = lazy(() => import('../MediaDialog'));

export interface Props {
  onMouseMove?: MouseEventHandler<HTMLImageElement>;
  onMouseLeave?: MouseEventHandler<HTMLImageElement>;
}

const MediaListContainer: FC<Props> = ({ onMouseMove, onMouseLeave }) => {
  const { isDesktop } = useDeviceType();
  const product = usePdvStore(pdvSelectors.product);
  const [selected, setSelected] = useState<number | null>(null);
  const uiDispatch = useUiDispatch();
  const mediaCollection = product.MediaCollection || [];
  const productMainImageUrl = product ? getMainImageURL(product) : '';

  return (
    <>
      <Head>
        <link
          rel="preload"
          as="image"
          // Preload the first image to improve LCP on desktop
          href={productMainImageUrl}
        />
      </Head>
      <MediaList
        items={mediaCollection}
        isHavingBanner={product.IsBestPriceGuarantee}
        onClickBanner={() =>
          uiDispatch.addPdvInteractedListener({
            interactionType: PageInteractionType.BEST_PRICE_GUARANTEE,
          })
        }
      >
        {({ item, index }) => (
          <MediaCard
            // prioritize loading for first and second image to improve LCP on desktop
            priority={index === 0}
            media={item}
            index={index}
            onClick={setSelected}
            onMouseMove={onMouseMove}
            onMouseLeave={onMouseLeave}
            className="rounded-lg"
          />
        )}
      </MediaList>

      {isDesktop || selected !== null ? (
        <Suspense fallback={<Loading isFullScreen />}>
          <MediaDialog
            items={mediaCollection}
            selectedIndex={selected}
            setSelected={setSelected}
          />
        </Suspense>
      ) : null}
    </>
  );
};

export default MediaListContainer;
