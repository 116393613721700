import clsx from 'clsx';
import dynamic from 'next/dynamic';
import { FC, Suspense, lazy, memo, useEffect } from 'react';
import VariationDetail from 'components/Variation/VariationDetail';
import { PDVElementIDs } from 'constants/pdv';
import withDynamicLoadBelowTheFold from 'hocs/withDynamicLoadBelowTheFold';
import useHydrated from 'hooks/useHydrated';
import { usePdvStore } from 'stores/pdv';
import { product as productSelector } from 'stores/pdv/selectors';
import ActionButtonsSkeleton from '../ActionButtons/ActionButtonsSkeleton';
import MainInfo from '../MainInfo/MainInfo';
import SizeError from '../SizeError/SizeError';
import SizeInformation from '../SizeInformation';
import StoreInfoContainer from '../StoreInfo/StoreContainer';
import PhotoSection from './PhotoSection';

const DynamicCTASection = lazy(() => import('./CTASection'));
const ProductDetailAsyncSection = withDynamicLoadBelowTheFold(
  dynamic(() => import('./ProductDetailAsyncSection')),
  { scrollTo: 50 },
);

const ProductDetail: FC = () => {
  const product = usePdvStore(productSelector);
  const isHydrated = useHydrated();

  useEffect(() => {
    if (!product.ConfigSku) {
      return;
    }

    import('utils/pdv/last-viewed-skus')
      .then(({ addLastViewedSkus }) => {
        addLastViewedSkus(product?.ConfigSku || '');
      })
      .catch(() => {});
  }, [product.ConfigSku]);

  return (
    <div
      id={PDVElementIDs.PRODUCT_DETAIL}
      className="relative flex flex-col gap-4 tablet:flex-row tablet:items-stretch tablet:gap-8 desktop:gap-6"
    >
      <div className="relative flex min-w-0 basis-7/12 flex-col">
        <Suspense fallback={null}>
          <PhotoSection />
        </Suspense>
      </div>

      <div className={clsx('relative min-w-0 basis-5/12 tablet:pr-4')}>
        <div
          key={product.ConfigSku}
          className="scroll-mt-20 px-4 tablet:px-0"
          id={PDVElementIDs.SECTION_DETAILS}
        >
          <Suspense fallback={null}>
            <MainInfo />
          </Suspense>

          <Suspense fallback={null}>
            <VariationDetail />
          </Suspense>

          <Suspense fallback={null}>
            <SizeError />
            <SizeInformation />
          </Suspense>
        </div>

        <div
          id="section__CTA"
          className="px-4 tablet:px-0"
        >
          {isHydrated ? (
            <Suspense fallback={<ActionButtonsSkeleton />}>
              <DynamicCTASection />
            </Suspense>
          ) : (
            <ActionButtonsSkeleton />
          )}
        </div>

        <ProductDetailAsyncSection />

        {/* Dont put it to DynamicSection because of SEO */}
        <Suspense fallback={null}>
          <StoreInfoContainer />
        </Suspense>
      </div>
    </div>
  );
};

export default memo(ProductDetail);
