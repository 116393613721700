export enum PageInteractionEventName {
  SIZE_GUIDE_CLICKED = 'Size Guide Clicked',
  SIZE_ONBOARDING_LOADED = 'Size Onboarding Loaded',
  SIZE_CHART_VIEWED = 'Size Chart Viewed',
  MEASUREMENT_GUIDE_LOADED = 'Measurement Guide Loaded',
  MODELS_MEASUREMENT_VIEWED = 'Models Measurement Viewed',

  ITEM_MEASUREMENT_CHART_VIEWED = 'Item Measurement Chart Viewed',
  ITEM_TAB_CLICKED = 'Item Tab Clicked',
  FIT_INDICATOR_VIEWED = 'Fit Indicator Viewed',
  SIZE_SYSTEM_CLICKED = 'Size System Clicked',

  /** Review translation  */
  REVIEW_TRANSLATION_CLICKED = 'Review Translation Clicked',

  /** Eye Fit U */
  EYEFITU_LOADED = 'EyeFitU Loaded',
  EYEFITU_CLICKED = 'EyeFitU Clicked',

  /** Meet the Seller */
  PDV_SELLER_BLOCK_CLICKED = 'PDV Seller Block Clicked',
}

export enum PageInteractionType {
  SIZE_GUIDE_CLICKED = 'size_guide_clicked',
  BODY_CM_INCH_CLICKED = 'body_cm_inch_clicked',
  ITEM_CM_INCH_CLICKED = 'item_cm_inch_clicked',
  VIEW_ALL_SIZES_CLICKED = 'view_all_sizes_clicked',

  /** Size guide */
  MEASUREMENT_IMAGE_DISPLAYED = 'measurement_image_displayed',
  MODEL_MEASUREMENT_DISPLAYED = 'model_measurement_displayed',

  SIZE_GUIDE_TAB_CLICKED = 'size_guide_tab_clicked',
  SIZE_SYSTEM_CLICKED = 'size_system_clicked',

  BODY_SIZE_CLICKED = 'body_size_clicked',
  ITEM_SIZE_CLICKED = 'item_size_clicked',

  /** GetTheLook */
  LOOKS_BTN_CLICKED = 'looks_btn_clicked',

  /** Vourcher */
  VOUCHERS_VIEW_ALL_BTN_CLICKED = 'vouchers_view_all_btn_clicked',
  VOUCHERS_TNC_BTN_VOUCHERS_CLICKED = 'vouchers_tnc_btn_vouchers_clicked',

  /** USP */
  USP_LEARN_MORE_CLICKED = 'usp_learn_more_clicked',

  /** Seller */
  SELLER_VISIT_STORE_CLICKED = 'seller_visit_store_clicked',

  /** Ratings & Reviews */
  RATINGSREVIEWS_KEY_INFO_CLICKED = 'ratingsreviews_key_info_clicked',
  RATINGSREVIEWS_VIEW_ALL_CLICKED = 'ratingsreviews_view_all_clicked',

  /** Brand name */
  BRANDNAME_CLICKED = 'brandname_clicked',

  /** Product Image */
  PRODUCT_IMAGE_SCROLLED = 'product_image_scrolled',

  /** Collection block */
  COLLECTION_BLOCK_CLICKED = 'collection_block_clicked',

  /** Cart */
  CART_BTN_CLICKED = 'cart_btn_clicked',

  /** Product specs */
  ABOUT_THE_PRODUCT_CLICKED = 'about_the_product_clicked',

  /** AI Description */
  ABOUT_THE_PRODUCT_VIEWED = 'about_the_product_viewed',
  PRODUCT_DESCRIPTION_LENGTH = 'product_description_length',

  /** Earth Edit */
  EARTH_EDIT_BANNER_CLICKED = 'Earth Edit Banner Clicked',
  EARTH_EDIT_TAG_LEARN_MORE_CLICKED = 'Earth Edit Tag Learn More Clicked',

  /** Video block */
  PRODUCT_VIDEO_CLICKED = 'product_video_clicked',

  /** Meet the Seller */
  LUXURY_FAQ_CLICKED_FROM_SELLERBLOCK = 'luxury-faq-clicked-from-sellerblock',

  /** Best price guarantee */
  BEST_PRICE_GUARANTEE = 'Best Price Guarantee Clicked',
}
