import { FC } from 'react';
import { UniversalLinks } from 'components/SEO/UniversalLinks';
import { usePdvStore } from 'stores/pdv';
import * as pdvSelectors from 'stores/pdv/selectors';

const PDVUniversalLinks: FC = () => {
  const product = usePdvStore(pdvSelectors.product);

  return <UniversalLinks destination={`d/${product.ConfigSku}`} />;
};

export default PDVUniversalLinks;
