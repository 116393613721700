import { ZDTProduct } from '@zalora/doraemon-ts';
import { useRouter } from 'next/router';
import { FC, memo } from 'react';
import { PageInfo } from 'components/SEO/PageInfo';
import { DEFAULT_LANGUAGE, i18nRegionalLanguages } from 'i18n/config';
import { usePdvStore } from 'stores/pdv';
import * as pdvSelectors from 'stores/pdv/selectors';
import { getRobots } from 'utils/product';
import { getProductUrlFromAbsoluteUrl } from 'utils/url';

const getPageInfoText = (product: ZDTProduct.Product, country: string | undefined) => {
  const { Brand, Name, SpecialPrice, Price } = product;
  const year = new Date().getFullYear();

  switch (country) {
    case 'en-SG': {
      return {
        title: `Buy ${Brand} ${Name} ${year} Online | ZALORA`,
        description: `Shop ${Brand} ${Name} | ${SpecialPrice || Price} ${year} @ ZALORA`,
      };
    }
    case 'en-MY': {
      return {
        title: `Buy ${Brand} ${Name} Online | ZALORA Malaysia`,
        description: `Shop ${Brand} ${Name} NOW only ${
          SpecialPrice || Price
        } at ZALORA Malaysia | FREE Delivery Above RM99 ✓ Cash On Delivery ✓ 30 Days Free Return ✓`,
      };
    }
    case 'en-PH': {
      return {
        title: `Buy ${Brand} ${Name} ${year} Online | ZALORA Philippines`,
        description: `Shop ${Brand} ${Name} NOW only ${
          SpecialPrice || Price
        } Online at ZALORA Philippines | Nationwide Shipping ✓ Cash On Delivery ✓ Cashback ✓ 30 Days Free Returns`,
      };
    }
    case 'en-HK': {
      return {
        title: `${Brand} ${Name} ${year} | Buy ${Brand} Online | ZALORA Hong Kong`,
        description: `Shop ${Brand} ${Name} ${year} Online @ZALORA HK. ✓FREE Delivery Above $299 ✓Cash On Delivery ✓30 Days Free Return ✓Cashback`,
      };
    }
    case 'id-ID': {
      return {
        title: `Jual ${Brand} ${Name} Original ${year} | ZALORA Indonesia ®`,
        description: `Beli ${Brand} ${Name} dengan harga hanya ${
          SpecialPrice || Price
        } di ZALORA Indonesia ® | COD ✔️ Garansi 30 Hari ✔️ Gratis Ongkir ✔️ Original ✔️ Cashback ✔️ | Belanja Sekarang!`,
      };
    }
    case 'zh-HK': {
      return {
        title: `網上選購 ${Brand} ${Name} ${year} 系列 | ZALORA香港`,
        description: `網上選購 ${Brand} ${Name} ${year} 系列 @ ZALORA香港。✓買滿$299即享免費送貨 ✓貨到支付現金 ✓30天內免費退貨`,
      };
    }
    case 'zh-TW': {
      return {
        title: `線上選購 ${Brand} ${Name} | ZALORA 台灣`,
        description: `購買${Brand} ${Name} @ ZALORA 台灣｜滿額免運✓ 貨到付款✓ 30天免費退貨✓ 國際與本土品牌✓ 100%正品✓`,
      };
    }

    default: {
      return {
        title: `Buy ${Brand} ${Name} ${year} Online | ZALORA`,
        description: `Shop ${Brand} ${Name} | ${SpecialPrice || Price} ${year} @ ZALORA`,
      };
    }
  }
};

const PDVPageInfo: FC = () => {
  const { locale } = useRouter();
  const { asPath } = useRouter();

  // Always fallback localhost to en-SG
  const country = locale === DEFAULT_LANGUAGE ? i18nRegionalLanguages['en-SG'] : locale;

  const product = usePdvStore(pdvSelectors.product);
  const canonicalLink = getProductUrlFromAbsoluteUrl(product.Url || '');
  // Get robots meta tag by asPath instead of query
  // because query from useRouter could having value even real URL does not include any query params
  // because of as attribute in Link component of Nextjs
  const robots = getRobots(asPath);

  const { title, description } = getPageInfoText(product, country);

  return (
    <PageInfo
      title={title}
      description={description}
      canonical={canonicalLink}
      robots={robots}
    />
  );
};

export default memo(PDVPageInfo);
