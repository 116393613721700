import { FC, lazy, memo } from 'react';
import { useDeviceType } from 'hooks/useDeviceType';
import MediaListContainer from '../MediaList/MediaListContainer';

const PhotoSectionZoom = lazy(() => import('./PhotoSectionZoom'));

const PhotoSection: FC = () => {
  const { isDesktop, isPending } = useDeviceType();

  if (isDesktop && !isPending) {
    return <PhotoSectionZoom />;
  }

  return (
    <div className="p-2 tablet:p-4 desktop:p-0">
      <MediaListContainer />
    </div>
  );
};

export default memo(PhotoSection);
