import { ChevronBreadcrumbsIcon } from '@zalora/zui-icons';
import clsx from 'clsx';
import Link from 'next/link';
import { FC } from 'react';
import { TestIdsBreadcrumb } from 'constants/e2eIds/breadcrumb-test-ids';
import { Routes } from 'constants/routes';

interface Props {
  className?: string;
  items: BreadcrumbItemProps[];
  onBreadcrumbItemClick?: (label: string, deeplink: string) => void;
}

export interface BreadcrumbItemType {
  label: string;
  url: string;
}

export interface BreadcrumbItemProps extends BreadcrumbItemType {
  onBreadcrumbItemClick?: (label: string, deeplink: string) => void;
}

const BreadcrumbItem: FC<BreadcrumbItemProps> = ({ label, onBreadcrumbItemClick, url }) => {
  // TODO: will be removed after homepage, account page is released
  if (url === Routes.HOMEPAGE || url === Routes.ACCOUNT_DETAILS) {
    return (
      <a
        className="px-2 text-neutral-500"
        href={url}
        // TODO: we should update it after catalog page is released
        // just fire the tracking event without others action
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onBreadcrumbItemClick && onBreadcrumbItemClick(label, url);
          window.location.href = url;
        }}
      >
        {label}
      </a>
    );
  }

  return (
    <Link href={url}>
      <a
        className="px-2 text-neutral-500"
        onClick={() => {
          onBreadcrumbItemClick && onBreadcrumbItemClick(label, url);
        }}
      >
        {label}
      </a>
    </Link>
  );
};

const Breadcrumbs: FC<Props> = ({ items, onBreadcrumbItemClick, className }) => {
  if (!items || items.length === 0) {
    return <div className="desktop:mb-5 desktop:h-5" />;
  }

  return (
    <ul
      className={clsx('flex h-5 items-center text-xs', className)}
      data-test-id="breadcrumbs"
    >
      {items.map((item, index) => {
        return index === items.length - 1 ? (
          <li
            key={item.url}
            data-test-id={TestIdsBreadcrumb.BREADCRUMB}
            data-url={item.url}
          >
            <h2 className="px-2">{item.label}</h2>
          </li>
        ) : (
          <li
            key={item.url}
            data-test-id={TestIdsBreadcrumb.BREADCRUMB}
            data-url={item.url}
          >
            <BreadcrumbItem
              onBreadcrumbItemClick={onBreadcrumbItemClick}
              label={item.label}
              url={item.url}
            />
            <ChevronBreadcrumbsIcon className="!h-3 !w-3" />
          </li>
        );
      })}
    </ul>
  );
};

export default Breadcrumbs;
