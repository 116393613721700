import { SkeletonItem } from 'components/skeleton';

const ActionButtonsSkeleton = () => {
  return (
    <SkeletonItem
      className="h-12"
      height="h-12"
    />
  );
};

export default ActionButtonsSkeleton;
