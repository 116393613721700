import { FC } from 'react';
import { SkeletonItem } from 'components/skeleton';

const PromotionSkeleton: FC = () => {
  return (
    <div className="flex gap-x-2">
      <SkeletonItem
        height="h-23.5"
        width="w-full"
        className="max-w-80"
      />
      <SkeletonItem
        height="h-23.5"
        width="w-19"
        className="min-w-[94px]"
      />
    </div>
  );
};

export default PromotionSkeleton;
