import { FC, useMemo } from 'react';
import SizeButtonsLoader from 'components/SizeButtons/SizeButtonsLoader';
import SizeSelectionFooterSkeleton from 'components/SizeSelection/SizeSelectionFooterSkeleton';
import { SkeletonItem } from 'components/skeleton/SkeletonItem';
import { TestIdsPDV } from 'constants/e2eIds/pdv-test-ids';
import withDynamicLoadIntersection from 'hocs/withDynamicLoadIntersection';
import { usePdvDispatch, usePdvStore } from 'stores/pdv';
import * as pdvSelectors from 'stores/pdv/selectors';

const SizeSelectionHeader = withDynamicLoadIntersection(
  () => import('components/SizeSelection/SizeSelectionHeader'),
  {
    threshold: 0,
    rootMargin: '200px',
    fallback: (
      <SkeletonItem
        height="h-[26px]"
        className="mb-1 tablet:mb-2"
      />
    ),
  },
);

const SizeSelectionFooter = withDynamicLoadIntersection(
  () => import('components/SizeSelection/SizeSelectionFooter'),
  { threshold: 0, rootMargin: '200px', fallback: <SizeSelectionFooterSkeleton /> },
);

const SizeInformation: FC = () => {
  const outOfStockContext = usePdvStore(pdvSelectors.outOfStockContext);
  const product = usePdvStore(pdvSelectors.product);
  const selectedSizeData = usePdvStore(pdvSelectors.selectedSizeData);
  const pdvDispatch = usePdvDispatch();

  const SizeButtons = useMemo(
    () =>
      withDynamicLoadIntersection(() => import('components/SizeButtons/SizeButtons'), {
        fallback: <SizeButtonsLoader product={product} />,
      }),
    [product.ConfigSku],
  );

  if (!product.Simples || !product.Simples.length) {
    return null;
  }

  return (
    <div
      data-test-id={TestIdsPDV.SIZE_SELECTION_OPTION_BOX}
      className="mb-4 tablet:mb-5"
    >
      <SizeSelectionHeader
        product={product}
        selectedSizeData={selectedSizeData}
        setSelectedSizeData={pdvDispatch.setSelectedSizeData}
      />

      <SizeButtons
        onClickMoreAction={pdvDispatch.setOutOfStockContext}
        outOfStockContext={outOfStockContext}
        product={product}
        selectedSizeData={selectedSizeData}
        setSelectedSizeData={pdvDispatch.setSelectedSizeData}
        shouldShowLimitedItems
      />

      <SizeSelectionFooter
        product={product}
        selectedSizeData={selectedSizeData}
        setSelectedSizeData={pdvDispatch.setSelectedSizeData}
      />
    </div>
  );
};

export default SizeInformation;
