import { FC } from 'react';
import { OpenGraphTags } from 'components/SEO/OpenGraphTags';
import { usePdvStore } from 'stores/pdv';
import * as pdvSelectors from 'stores/pdv/selectors';
import { stripTags } from 'utils/open-graph';

const PDVOpenGraphTags: FC = () => {
  const product = usePdvStore(pdvSelectors.product);
  const { MediaCollection, Name, ShortDescription, Url } = product;

  if (!Name || !ShortDescription || !Url) {
    return null;
  }

  return (
    <OpenGraphTags
      title={Name}
      description={stripTags(ShortDescription)}
      url={Url}
      medias={MediaCollection || undefined}
    />
  );
};

export default PDVOpenGraphTags;
