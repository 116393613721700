import { ComponentPropsWithoutRef, PropsWithChildren, RefObject, useEffect, useRef } from 'react';
import useIntersectionObserver from 'hooks/useIntersectionObserver';

export interface Props extends ComponentPropsWithoutRef<'div'> {
  container: RefObject<HTMLDivElement>;
  index: number;
  className?: string;
  onVisible?: (index: number) => void;
}

const MediaItem = ({
  container,
  index,
  className,
  children,
  onVisible,
  ...props
}: PropsWithChildren<Props>) => {
  const ref = useRef<HTMLDivElement>(null);
  const entry = useIntersectionObserver(ref, {
    root: container.current,
    threshold: 1,
  });
  const isVisible = !!entry?.isIntersecting;

  useEffect(() => {
    if (onVisible && isVisible) {
      onVisible(index);
    }
  }, [isVisible, onVisible, index]);

  return (
    <div
      className={className}
      ref={ref}
      {...props}
    >
      {children}
    </div>
  );
};

export default MediaItem;
