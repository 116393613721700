import { ZDTProduct } from '@zalora/doraemon-ts';
import clsx from 'clsx';
import { MouseEventHandler } from 'react';
import { usePdvStore } from 'stores/pdv';
import * as pdvSelectors from 'stores/pdv/selectors';
import { useUiDispatch } from 'stores/ui';
import { ProductMediaType } from 'types/ZDTEnums';
import { getStaticAsset } from 'utils/domains';
import { getMainImageURL, getName } from 'utils/product';
import { PageInteractionType } from '../types';
import PlayIcon from './play.svg';

export interface Props {
  media: ZDTProduct.Media;
  index: number;
  className?: string;
  priority?: boolean;
  onClick: (index: number) => void;
  onMouseMove?: MouseEventHandler<HTMLImageElement>;
  onMouseLeave?: MouseEventHandler<HTMLImageElement>;
}

export const MediaCard = ({
  media,
  index,
  className,
  priority,
  onClick,
  onMouseMove,
  onMouseLeave,
}: Props) => {
  const product = usePdvStore(pdvSelectors.product);
  const productName = getName(product);
  const { ContentType, Url } = media;
  const isVideo = ContentType === ProductMediaType.PRODUCT_MEDIA_TYPE_VIDEO;
  const src = (isVideo ? getMainImageURL(product) : Url) || '';
  const uiDispatch = useUiDispatch();

  const image = (
    <img
      src={src}
      // this background image will be the LCP if the main image takes a lot of time to load
      // it'll be helpful in case the size of main image is too large. We can ensure the LCP is always good
      style={{
        background: `url("${getStaticAsset('/static-assets/images/product_skeleton.webp')}")`,
      }}
      loading={priority ? 'eager' : 'lazy'}
      alt={productName}
      className={clsx(isVideo ? 'cursor-pointer' : 'cursor-zoom-in', className)}
      onClick={() => onClick(index)}
      onMouseMove={isVideo ? undefined : onMouseMove}
      onMouseLeave={isVideo ? undefined : onMouseLeave}
    />
  );

  if (isVideo) {
    return (
      <div
        className="flex h-full w-full items-center justify-center"
        onClick={() => {
          uiDispatch.addPdvInteractedListener({
            interactionType: PageInteractionType.PRODUCT_VIDEO_CLICKED,
          });
        }}
      >
        <PlayIcon className="pointer-events-none absolute z-component h-[45px] w-[45px]" />
        {image}
      </div>
    );
  }

  return image;
};
