import { Suspense, lazy, memo, useState } from 'react';
import ImpressionWrapper from 'components/ImpressionWrapper/ImpressionWrapper';
import { TestIdsPDV } from 'constants/e2eIds/pdv-test-ids';
import { usePdvStore } from 'stores/pdv';
import {
  product as productSelector,
  selectedSimple as selectedSimpleSelector,
} from 'stores/pdv/selectors';
import { removeLastSlashs } from 'utils/url';

const StoreLayout = lazy(() => import('./StoreLayout'));

const StoreContainer = () => {
  const product = usePdvStore(productSelector);
  const [shouldShowStore, setShouldShowStore] = useState(false);
  const selectedSimple = usePdvStore(selectedSimpleSelector);

  const store = selectedSimple?.FulfillmentInformation || product.FulfillmentInformation;

  const { SellerUrlKey, SellerName } = store || {};
  const visitStoreUrl = removeLastSlashs(`/store/${SellerUrlKey}`);

  return (
    <ImpressionWrapper
      key={TestIdsPDV.STORE_SECTION}
      data-test-id={TestIdsPDV.STORE_SECTION}
      id="section__store"
      onImpression={() => setShouldShowStore(true)}
      options={{ rootMargin: '200px', threshold: 0 }}
    >
      {shouldShowStore ? (
        <Suspense fallback={null}>
          <StoreLayout />
        </Suspense>
      ) : (
        <div className="mx-4 mt-10 flex aspect-[45/13] items-center overflow-hidden rounded-lg bg-grey-20 tablet:mx-0">
          <a
            href={visitStoreUrl}
            aria-label={`${SellerName} store`}
          />
        </div>
      )}
    </ImpressionWrapper>
  );
};

export default memo(StoreContainer);
