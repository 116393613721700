import { ComponentPropsWithoutRef, forwardRef } from 'react';
import withIntersectionObserver from 'hocs/withIntersectionObserver';

interface Props extends ComponentPropsWithoutRef<'div'> {
  key: string;
  className?: string;
}

const ImpressionWrapper = forwardRef<HTMLDivElement, Props>(
  ({ key, className, children, ...props }, ref) => {
    return (
      <div
        key={key}
        className={className}
        ref={ref}
        {...props}
      >
        {children}
      </div>
    );
  },
);

export default withIntersectionObserver(ImpressionWrapper);
