import { FC } from 'react';
import { SkeletonItem } from 'components/skeleton';

const InstallmentSkeleton: FC = () => {
  return (
    <SkeletonItem
      height="h-4"
      width="w-2/3"
      className="mt-1"
    />
  );
};

export default InstallmentSkeleton;
