import { FC, Suspense, lazy } from 'react';
import useHydrated from 'hooks/useHydrated';
import { usePdvStore } from 'stores/pdv';
import * as pdvSelectors from 'stores/pdv/selectors';

const RatingScore = lazy(() => import('./RatingScore'));
const RatingScoreSkeleton = <div className="h-[26px]" />;

const RatingScoreContainer: FC = () => {
  const reviewStatistics = usePdvStore(pdvSelectors.reviewStatistics);
  const isHydrated = useHydrated();

  if (!isHydrated || !reviewStatistics?.ReviewCount) {
    return RatingScoreSkeleton;
  }

  return (
    <Suspense fallback={RatingScoreSkeleton}>
      <span>
        <RatingScore
          className="align-middle"
          ariaLabel="Go To Reviews"
        />
      </span>
    </Suspense>
  );
};

export default RatingScoreContainer;
