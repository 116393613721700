import { ComponentType, FC, startTransition, useEffect, useState } from 'react';
import useScrollToObserver from 'hooks/useScrollToObserver';

type DynamicOptions = {
  scrollTo?: number;
  timeout?: number;
};

const DEFAULT_TIMEOUT = 0;

const withDynamicLoadBelowTheFold =
  <P extends object>(WrappedComponent: ComponentType<P>, option?: DynamicOptions): FC<P> =>
  (props: P) => {
    const { scrollTo = 100, timeout = DEFAULT_TIMEOUT } = option || {};
    const isIntersecting = useScrollToObserver({
      scrollTo,
    });

    const [hasLoaded, setHasLoaded] = useState(false);

    useEffect(() => {
      let timeoutId: ReturnType<typeof setTimeout>;

      const loadingHandler = () => {
        timeoutId = setTimeout(() => {
          setHasLoaded(true);
        }, timeout);
      };

      document.addEventListener('DOMContentLoaded', loadingHandler, { once: true });

      if (document.readyState !== 'loading') {
        loadingHandler();
      }

      return () => {
        clearTimeout(timeoutId);
        document.removeEventListener('DOMContentLoaded', loadingHandler);
      };
    }, []);

    useEffect(() => {
      if (isIntersecting) {
        startTransition(() => {
          setHasLoaded(true);
        });
      }
    }, [isIntersecting, setHasLoaded]);

    return hasLoaded ? <WrappedComponent {...props} /> : null;
  };

export default withDynamicLoadBelowTheFold;
