import { FC } from 'react';
import { SkeletonItem } from 'components/skeleton';

const PromotionPriceSkeleton: FC = () => {
  return (
    <SkeletonItem
      className="my-3 mt-6"
      height="h-6"
      width="w-40"
    />
  );
};

export default PromotionPriceSkeleton;
