import { ComponentType, FC, startTransition, useEffect, useState } from 'react';
import useScrollToObserver from 'hooks/useScrollToObserver';

type DynamicOptions = {
  scrollTo?: number;
};

const withDynamicLoadScrollTo =
  <P extends object>(WrappedComponent: ComponentType<P>, option?: DynamicOptions): FC<P> =>
  (props: P) => {
    const { scrollTo = 100 } = option || {};
    const isIntersecting = useScrollToObserver({
      scrollTo,
    });

    const [hasLoaded, setHasLoaded] = useState(false);

    useEffect(() => {
      if (isIntersecting) {
        startTransition(() => {
          setHasLoaded(true);
        });
      }
    }, [isIntersecting, setHasLoaded]);

    return hasLoaded ? <WrappedComponent {...props} /> : null;
  };

export default withDynamicLoadScrollTo;
