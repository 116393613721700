import { AlertIcon } from '@zalora/zui-icons';
import clsx from 'clsx';
import { FC } from 'react';
import { usePdvStore } from 'stores/pdv';
import * as pdvSelectors from 'stores/pdv/selectors';

interface Props {
  className?: string;
}

const SizeError: FC<Props> = ({ className }) => {
  const product = usePdvStore(pdvSelectors.product);
  const sizeErrorMessage = usePdvStore(pdvSelectors.sizeErrorMessage);
  const selectedSizeData = usePdvStore(pdvSelectors.selectedSizeData);

  // Hide if product has restricted sku
  if (!product.Simples) {
    return null;
  }

  if (!sizeErrorMessage || selectedSizeData.size) {
    // fixed height here, so that layout won't be change after show hide this component.
    return <div className="h-10" />;
  }

  return (
    <span
      className={clsx('flex w-full items-center rounded-lg bg-red-10 p-2 text-grey-100', className)}
    >
      <AlertIcon className="mr-1" /> {sizeErrorMessage}
    </span>
  );
};

export default SizeError;
