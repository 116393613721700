import { ZDTProduct } from '@zalora/doraemon-ts';
import { GetServerSideProps, GetServerSidePropsContext } from 'next';
import dynamic from 'next/dynamic';
import { unstable_serialize as unstableSerialize } from 'swr';
import { HEADERS } from 'api/APIClient';
import * as products from 'api/products';
import PDVLayout from 'components/Layout/PDVLayout';
import { FF_CONFIG_BY_ROUTE } from 'constants/bob-feature-flags';
import FeatureFlags from 'constants/feature-flags';
import { THUMBORIZE_IMAGE_FORMAT, THUMBORIZE_IMAGE_QUALITY } from 'constants/images';
import { DEFAULT_REVIEW_STATISTICS } from 'constants/review';
import { Routes } from 'constants/routes';
import { NextPageWithLayout } from 'pages/_app.page';
import {
  constructCacheKey,
  fetchBobFeatureFlags,
  isBobFeatureFlagEnabled,
} from 'utils/bob-feature-flags';
import { serverSideTranslations } from 'utils/i18n';
import fetchLayoutData from 'utils/server-layout-data';
import { preloadStoreState } from 'utils/stores/preloadStoreState';
import {
  getProductUrlFromAbsoluteUrl,
  getProductUrlFromSlug,
  getQueryExcludeKeys,
  setQueryParams,
} from 'utils/url';
import PdvPage from './PdvPage';

const PdvNotFound = dynamic(() => import('components/pdv/PdvNotFound'), {
  ssr: false,
  loading: () => null,
});

interface Props {
  isNotFound: boolean;
}

const Product: NextPageWithLayout<Props> = ({ isNotFound }) => {
  if (isNotFound) {
    return <PdvNotFound />;
  }

  return (
    <PDVLayout>
      <PdvPage />
    </PDVLayout>
  );
};

export const getServerSideProps: GetServerSideProps = async (
  context: GetServerSidePropsContext,
) => {
  const { locale = '', params, query, req } = context;
  const configSkuOrSlug = params?.slug;

  if (typeof configSkuOrSlug !== 'string' || !configSkuOrSlug) {
    return { notFound: true };
  }

  const queryParamsWithoutSlug = getQueryExcludeKeys(query, ['slug']) as Record<string, string>;

  if (configSkuOrSlug.endsWith('.html')) {
    // to make consistent URL for PDV, we will auto remove .html at the end it exist
    return {
      redirect: {
        destination: setQueryParams(getProductUrlFromSlug(configSkuOrSlug), queryParamsWithoutSlug),
        statusCode: 301,
      },
    };
  }

  const {
    status,
    data: product,
    error,
  } = await products.getProductDetails<ZDTProduct.Product>(
    products.ProductApis.details,
    configSkuOrSlug,
    {
      headers: {
        [HEADERS.CONTENT_LANG]: locale as string,
      },
      params: {
        image_format: THUMBORIZE_IMAGE_FORMAT,
        image_quality: THUMBORIZE_IMAGE_QUALITY,
        withBloatedPromos: false,
      },
    },
  );

  if (error) {
    throw error; // Let Next throw a 500 if any error occurred
  }

  if (status !== 200) {
    if (!req.url) {
      return { notFound: true };
    }

    const [layoutData, translationData = null, enabledFeatureFlags] = await Promise.all([
      fetchLayoutData(locale),
      serverSideTranslations(locale, ['common', 'pdv-not-found', 'footer', 'interactive-journey']),
      fetchBobFeatureFlags({
        routeKey: Routes.PRODUCT,
        featureFlagConfig: FF_CONFIG_BY_ROUTE,
        options: { isServerOnly: true },
      }),
    ]);

    if (!isBobFeatureFlagEnabled(enabledFeatureFlags, FeatureFlags.Bob.LOTUS_ENABLE_PDV_404)) {
      return { notFound: true };
    }

    const isPageRequest = req.url.startsWith('/p');

    // Page requests = 404, JSON requests = 200
    context.res.statusCode = isPageRequest ? 404 : 200;

    return {
      props: {
        trackPageCallTitle: 'Product not found',
        isNotFound: true,
        ...translationData,
        ...preloadStoreState({
          cart: {},
          optimizely: {},
          pdv: {},
          ui: layoutData,
        }),
        fallback: {
          [unstableSerialize(constructCacheKey(Routes.PRODUCT))]: enabledFeatureFlags, // Pass FF in fallback for server side use cases
        },
      },
    };
  }

  const productUrl = getProductUrlFromAbsoluteUrl(product?.Url || '');

  if (!!productUrl && configSkuOrSlug === product?.ConfigSku) {
    return {
      redirect: {
        destination: setQueryParams(productUrl, queryParamsWithoutSlug),
        statusCode: 301,
      },
    };
  }

  const activeSegment = product?.BreadcrumbsDetail?.Segment?.UrlKey || '';

  // Fetch parallelly for better performance
  const [
    reviewStatistics = null,
    specs = null,
    care = null,
    layoutData,
    translationData = null,
    enabledFeatureFlags,
  ] = await Promise.all([
    products.getProductReviewStatistics(configSkuOrSlug),
    products.getProductDetails<ZDTProduct.ProductSpecs>(
      products.ProductApis.specs,
      configSkuOrSlug,
    ),
    products.getProductDetails<ZDTProduct.ProductCare>(products.ProductApis.care, configSkuOrSlug),
    fetchLayoutData(locale, false, req), // TODO:`req` should be cleaned up once done FE-220
    serverSideTranslations(locale, ['common', 'pdv', 'footer', 'interactive-journey']),
    fetchBobFeatureFlags({
      routeKey: Routes.PRODUCT,
      featureFlagConfig: FF_CONFIG_BY_ROUTE,
      options: { isServerOnly: true },
    }),
  ]);

  return {
    props: {
      ...translationData,
      ...preloadStoreState({
        cart: {},
        delivery: {},
        optimizely: {},
        pdv: {
          product,
          reviewStatistics: reviewStatistics?.data || DEFAULT_REVIEW_STATISTICS,
          specs: specs?.data,
          care: care?.data,
        },
        ui: {
          ...layoutData,
          activeSegment,
        },
      }),
      fallback: {
        [unstableSerialize(constructCacheKey(Routes.PRODUCT))]: enabledFeatureFlags, // Pass FF in fallback for server side use cases
      },
    },
  };
};

export default Product;
