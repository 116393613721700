import { ZDTProduct, ZDTCatalog } from '@zalora/doraemon-ts';
import clsx from 'clsx';
import { default as NextLink } from 'next/link';
import { FC, PropsWithChildren } from 'react';
import { getProductUrlFromSku, getProductUrlFromSlug } from 'utils/url';
import { VariationsType } from './variant-layout';

interface Props {
  className?: string;
  variationsType: VariationsType;
  variations: ZDTProduct.ProductVariation[];
  product: ZDTProduct.Product | ZDTCatalog.Product;
  defaultLayout: string;
  gridLayout: string;
}

interface WrapperProps extends PropsWithChildren {
  className?: string;
  variationsType: VariationsType;
}

const VariationWrapper: FC<WrapperProps> = ({ children, variationsType, className }) => {
  switch (variationsType) {
    case VariationsType.REGULAR: {
      return (
        <div
          className={clsx(
            'relative flex aspect-[1/1.45] w-16 flex-col justify-center rounded-lg border',
            className,
          )}
        >
          {children}
        </div>
      );
    }
    case VariationsType.BEAUTY_ALL_HEX: {
      return (
        <div className={clsx('block p-0.5', className)}>
          <div className="h-7 bg-grey-10">{children}</div>
        </div>
      );
    }

    case VariationsType.BEAUTY_IMAGE_HEX: {
      return (
        <>
          <div className="mx-auto mb-1 block border">
            <div className="size-14 bg-grey-10">{children}</div>
          </div>
          <div className="mx-auto h-4 w-14 border bg-grey-10" />
        </>
      );
    }
    default: {
      return <>{children}</>;
    }
  }
};

const VariationListPlaceholder: FC<Props> = ({
  variations = [],
  className,
  variationsType,
  defaultLayout,
  gridLayout,
}) => {
  return (
    <div className={clsx(defaultLayout, gridLayout)}>
      {variations.map((variation) => {
        const { Link, ConfigSku = '' } = variation;
        const productUrl = Link ? getProductUrlFromSlug(Link) : getProductUrlFromSku(ConfigSku);

        return (
          <div
            key={variation.ConfigSku}
            className={clsx(
              'flex flex-col justify-center desktop:mb-1',
              variationsType === VariationsType.REGULAR
                ? 'desktop:w-12 desktop:min-w-12'
                : 'desktop:w-15 desktop:min-w-15',
            )}
          >
            <VariationWrapper
              variationsType={variationsType}
              className={className}
            >
              <NextLink
                passHref
                key={variation.ConfigSku}
                href={productUrl}
                aria-label={variation.Color || ''}
                title={variation.Color || ''}
              >
                <a href={productUrl} />
              </NextLink>
            </VariationWrapper>
          </div>
        );
      })}
    </div>
  );
};

export default VariationListPlaceholder;
